<template>
  <div class="home">
    <div>
      <h1 class="text-3xl text-gray-500 my-14">CONTACT</h1>
    </div>
    <div class="w-9/12 mx-auto">CONTACT page</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
// @ is an alias to /src
export default defineComponent({
  name: "CONTACT",
  components: {},
})
</script>

<style scoped></style>
